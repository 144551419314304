
table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    font-weight: 500;
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: white;
    font-weight: bold;
    background-color: #F79646;
}

tr:nth-child(even) {
    background-color: #FDE9D9;
}

tr:nth-child(odd) {
    background-color: white;
}


.custom-last tr:last-child {
    background-color: orange;
}
