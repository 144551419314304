.datePicker {
    width: max-content;
    padding: 16px 0 12px 0!important;
}

.react-datepicker {
    border: none;
    border-radius: 0;
}

.datePicker .react-datepicker__input-container {
    display: flex;
    justify-content: flex-end;
}

.react-datepicker-popper {
    left: -50px!important;
    bottom: unset !important;
}

.react-date-picker__calendar--open {
    top: 100% !important;
    bottom: unset !important;
}

.react-datepicker__current-month {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
}

.react-datepicker__day-names {
    margin-bottom: 0!important;
    border-bottom: none;
}

.react-datepicker__navigation-icon::before {
    border-color: #1269FB;
    border-style: solid;
    border-width: 2px 2px 0 0;
}

.react-datepicker__day-name {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 20px;
    text-align: center;
    width: 20px;
    height: 20px;
    margin: 3px 7px 7px 7px;
    border-top-left-radius: 4px;
    border-color: rgba(0, 0, 0, 0.08);
}

.react-datepicker__day-names {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border-bottom: none;
    border-bottom-left-radius: initial;
    border-bottom-right-radius: initial;
    margin-top: 8px;
}

.react-datepicker__day {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    width: 34px;
    height: 20px;
    margin: 0;
    padding: 1px 7px;
    border-radius: 0;
}

.react-datepicker__day:hover {
    border-radius: 0;
 }

.react-datepicker__day--in-range:hover {
    background-color: rgba(30, 30, 30, 0.1);
}

.react-datepicker__year-text--in-range:hover {
    background-color: rgba(30, 30, 30, 0.1)!important;
}

.react-datepicker__day--selected {
    background-color: rgba(30, 30, 30, 0.1)!important;
    border-radius: 0;
}

.react-datepicker__day--in-selecting-range {
    border-radius: 0;
    background-color: rgba(30, 30, 30, 0.1)!important;
}

.react-datepicker__month {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-top: none;
    border-radius: 0;
    margin: 0;
}

.react-datepicker__header  {
    border-bottom: none;
    background-color: white;
    padding: 0;
}

.react-datepicker__day--range-start {
    color: white;
    z-index: 2;
    position: relative;
    opacity: 1;
    background: linear-gradient(90deg, #fff 50%, rgba(30, 30, 30, 0.1) 50%)!important;
}

.react-datepicker__day--range-start::after{
    position: absolute;
    content: "";
    top: 0;
    left: 7px;
    z-index: -1;
    background: #1E1E1E!important;
    border-radius: 50%!important;
    width: 20px;
    height: 20px;
}

.react-datepicker__day--range-end {
    color: white;
    z-index: 2;
    position: relative;
    opacity: 1;
    background: linear-gradient(90deg, rgba(30, 30, 30, 0.1) 50%, #fff 50%)!important;
}

.react-datepicker__day--range-end::after{
    position: absolute;
    content: "";
    top: 0;
    left: 7px;
    z-index: -1;
    background: #1E1E1E!important;
    border-radius: 50%!important;
    width: 20px;
    height: 20px;
}

.react-datepicker__day--in-range {
    background: rgba(30, 30, 30, 0.1);
    border-radius: 0;
}

.react-datepicker__day .react-datepicker__day--010 .react-datepicker__day--in-selecting-range {
    background: #1E1E1E!important;
    border-radius: 0;
    color: white;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: rgba(30, 30, 30, 0.1);
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: white;
}


.datePicker {
    padding: 0!important;
    margin-bottom: 8px;
}
