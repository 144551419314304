.ant-switch-checked {
    background: #00af53 !important;
}

.ant-radio-checked .ant-radio-inner{
    border-color: rgba(0, 0, 0, 0.1) !important ;
}

.ant-radio-checked .ant-radio-inner:after{
    background-color: black!important;
}

.ant-radio:hover .ant-radio-inner {
    border-color: rgba(0, 0, 0, 0.1) ;
}

.ant-radio-inner {
    background-color: white!important;
}

.ant-checkbox {
    top: 0;
    margin-right: 4px;
}

.ant-checkbox-wrapper {
    display: flex;
    align-items: center;
}

.ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 0;
    background-color: white!important;
    border-color: grey!important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.1)!important;
    border-color: rgba(0, 0, 0, 0.1)!important;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: black !important;
}

.ant-radio-input {
    width: 20px;
    height: 20px;
}

.ant-radio-wrapper .ant-radio-inner {
    width: 20px;
    height: 20px;
}
.ant-checkbox-checked:after {
    border-color: rgba(0, 0, 0, 0.1)!important;
    border-radius: initial!important;
}

.ant-checkbox-wrapper:hover {
     border-color: rgba(0, 0, 0, 0.1)!important;
}

.ant-checkbox-checked:hover {
    background-color: white!important;
}

.ant-radio-wrapper:hover .ant-radio-inner {
     border-color: rgba(0, 0, 0, 0.1)!important;
}

.ant-radio-checked:after {
    border-color: rgba(0, 0, 0, 0.1)!important;
 }
