.login-form {
    max-width: 400px;
    width: 100%;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}
